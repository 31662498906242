<script lang="ts" setup>
import type { ComponentPublicInstance } from 'vue'
import { computed, ref, useTemplateRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { vIntersectionObserver } from '@vueuse/components'
import { KsButton, KsIcon, KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import useLicenseControlStore from '~/stores/licenseControl'
import { PendoTrackName } from '~/models/Pendo'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import usePendo from '~/composables/usePendo'
import { useFlashcardsQuery } from '~/composables/useFlashcardsQuery'
import FlashcardAnimation from '~/assets/lottie/Flashcard_Complete.json'
import TopBar from '~/components/utils/TopBar.vue'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import FullscreenButton from '~/components/utils/FullscreenButton.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import CloseButton from '~/components/utils/CloseButton.vue'
import Image from '~/components/media/Image.vue'
import AudioPlayer from '~/components/media/AudioPlayer.vue'
import FlashCard from '~/components/flashcards/FlashCard.vue'

const props = defineProps<{ locationId: number }>()

const { t } = useI18n()
const startButton = useTemplateRef<ComponentPublicInstance>('start')
const { buildAudioUrl } = useUrlBuilder()
const { checkItemLicense } = useLicenseControlStore()
const { pendoTrack, pendoFlushNow } = usePendo()

const scroller = ref<HTMLDivElement | null>(null)
const isFullscreen = ref(false)

const { data, isLoading } = useFlashcardsQuery(props.locationId)

const shuffledCards = ref(shuffle(data.value?.cards ?? []))
const activeCardIndex = ref(-1)
const isLast = ref(true)

const location = computed(() => data.value?.location)

watch(location, () => {
  if (!location.value) return
  setTitle(location.value.title)
  checkItemLicense(location.value)
}, { immediate: true })

watch(() => data.value?.cards, (newCards, oldCards) => {
  if (newCards && (oldCards?.length !== newCards?.length)) {
    shuffledCards.value = shuffle(newCards)
  }
})

enum Dir {
  Forwards = 1,
  Backwards = -1,
}

function scroll(dir: Dir) {
  const el = scroller.value
  if (!el) return

  el.scrollTo({
    left: el.scrollLeft + dir * el.clientWidth,
    behavior: 'smooth',
  })
}

function reload() {
  scroller.value?.scrollTo({ left: 0 })
  shuffledCards.value = shuffle(shuffledCards.value)
  startButton.value?.$el?.focus?.()
}

function shuffle<T>(cards: T[]): T[] {
  const newCards = [...cards]
  const random = Array.from({ length: newCards.length }, Math.random)
  return newCards.sort((a, b) => random[newCards.indexOf(a)] > random[newCards.indexOf(b)] ? 1 : -1)
}

function updateInView(index: number, inView: boolean) {
  if (inView) activeCardIndex.value = index
}

function updateInViewStartObserver([entry]: IntersectionObserverEntry[]) {
  if (entry.isIntersecting) activeCardIndex.value = -1
}

function updateInViewEndObserver([entry]: IntersectionObserverEntry[]) {
  isLast.value = !entry.isIntersecting
  if (isLast.value) return
  pendoTrack(PendoTrackName.FlashcardComplete, {
    title: document.title,
    url: window.location.href
  })
  pendoFlushNow()
}
</script>

<template>
  <TopBar
    variant="colorPair"
    :item="location"
    :color-pair="location?.colorPair"
    @fullscreen="isFullscreen = $event"
  />
  <!-- <div class="fixed right-4 top-4 z-10 flex items-center gap-4">
    <FullscreenButton
      v-if="location"
      :style="{
        backgroundColor: location.colorPair.background.rgb,
        color: location.colorPair.text.rgb,
        border: `1.5px solid ${location.colorPair.text.rgb}`
      }"
      @fullscreen="isFullscreen = $event"
    />
    <CloseButton
      v-if="location"
      :item="location"
      :style="{
        backgroundColor: location.colorPair.background.rgb,
        color: location.colorPair.text.rgb,
        border: `1.5px solid ${location.colorPair.text.rgb}`
      }"
      :is-fixed="false"
    />
  </div> -->

  <div
    v-if="isLoading"
    class="h-full"
  >
    <KsSkeletonWrapper class="grid h-full place-content-center place-items-center gap-4">
      <KsSkeleton
        height="4rem"
        width="30ch"
      />
      <KsSkeleton
        height="2rem"
        width="45ch"
      />
      <KsSkeleton
        height="3rem"
        width="20ch"
        class="!rounded-full"
      />
    </KsSkeletonWrapper>
  </div>

  <div
    v-else-if="location"
    ref="scroller"
    tabindex="0"
    class="relative flex h-full snap-x snap-mandatory items-center gap-[--card-spacing] overflow-x-scroll scroll-smooth px-[calc(10vh+var(--card-spacing))]"
    :style="{
      backgroundColor: location.colorPair.background.rgb,
      color: location.colorPair.text.rgb,
      scrollbarWidth: 'none',
      '--media-bg-color': location.colorPair.background.rgb,
      '--media-color': location.colorPair.text.rgb,
      '--card-width': isFullscreen ? '100dvw' : '80dvw',
      '--card-height': isFullscreen ? '100dvh' : '80dvh',
      '--card-spacing': isFullscreen ? '0' : '3rem',
    }"
    @keydown.left.prevent="scroll(Dir.Backwards)"
    @keydown.right.prevent="scroll(Dir.Forwards)"
  >
    <div
      v-intersection-observer="[updateInViewStartObserver, { threshold: .5 }]"
      class="grid h-[--card-height] w-[--card-width] flex-none snap-center snap-always place-content-center place-items-center gap-8"
    >
      <h1 class="text-5xl">
        {{ location.title }}
      </h1>
      <p class="text-xl">
        {{ t('flashcards.tip') }}
      </p>
      <KsButton
        ref="start"
        shape="rounded"
        variant="border"
        icon-right="arrow-right"
        :style="{
          background: location.colorPair.background.rgb,
          '--ks-border': location.colorPair.text.rgb,
          '--ks-borderhovertext': location.colorPair.isDark ? 'white' : 'black',
        }"
        size="large"
        @click="scroll(Dir.Forwards)"
      >
        {{ t('flashcards.start') }}
      </KsButton>
    </div>

    <FlashCard
      v-for="({ card, image, audio }, index) of shuffledCards.map((card) => ({
        card,
        image: (card.imageFront && data?.images && data.images.get(Number(card.imageFront.destinationContentId))!) || undefined,
        audio: (card.audioFront && data?.audioFiles && data.audioFiles.get(Number(card.audioFront.destinationContentId))!) || undefined,
      }))"
      :key="card.locationId"
      class="relative grid h-[--card-height] w-[--card-width] flex-none snap-center snap-always place-items-center overflow-hidden rounded-lg bg-white"
      :class="[location.textSizes.paragraph]"
      :style="{
        backgroundColor: location.colorPair.text.rgb,
        color: location.colorPair.background.rgb,
        '--flashcard-safe-inset': isFullscreen ? '4rem' : '0',
      }"
      @view="updateInView(index, $event)"
    >
      <template #front>
        <Image
          v-if="image"
          :content="image"
          class="max-h-full min-h-0 !w-full min-w-0 place-self-stretch"
          :class="image.contentTypeIdentifier === ContentType.Svg ? 'object-contain' : 'object-cover'"
        />
        <RichTextRenderer
          class="p-24"
          :class="{ 'sr-only': image }"
          :text="card.bodyFront ?? 'no text'"
        />
        <CopyrightButton
          v-if="image && 'copyright' in image"
          class="!absolute bottom-8 right-8 !w-auto text-lg"
          :copyright="image.copyright ?? ''"
          @keydown.enter.stop
          @keydown.space.stop
          @click.stop
        />
      </template>

      <template #back>
        <RichTextRenderer
          class="p-24"
          :class="[location.textSizes.heading]"
          :text="card.bodyBack ?? 'no text'"
        />
      </template>

      <template
        v-if="audio"
        #overlay
      >
        <AudioPlayer
          :media-id="audio.metadata?.contentItemId"
          :src="{
            src: buildAudioUrl(audio),
            type: audio.metadata?.mimeType ?? audio.file?.mimeType
          }"
          class="-translate-x-1/2 !rounded-full"
        >
          <template #controls>
            <media-play-button
              tabindex="-1"
              class="group grid size-32 place-content-center rounded-full bg-[--media-bg-color] text-3xl text-[--media-color] shadow-lg"
            >
              <KsIcon
                icon="play"
                variant="solid"
                class="hidden translate-x-[10%] group-data-[paused]:block"
              />
              <KsIcon
                icon="pause"
                variant="solid"
                class="group-data-[paused]:hidden"
              />
            </media-play-button>
          </template>
        </AudioPlayer>
      </template>
    </FlashCard>

    <div
      v-intersection-observer="[updateInViewEndObserver, { threshold: .5 }]"
      class="flex h-[--card-height] w-[--card-width] flex-none snap-center snap-always flex-col place-content-center place-items-center bg-center bg-no-repeat md:flex-row"
    >
      <LottieAnimation
        :animation-data="FlashcardAnimation"
        width="250px"
        height="500px"
        class="self-end"
      />
      <div class="flex flex-col items-center gap-8">
        <p class="text-center fluid-text-4xl">
          {{ t('flashcards.complete') }}
        </p>
        <KsButton
          shape="rounded"
          variant="border"
          icon-left="rotate-left"
          :style="{
            background: location.colorPair.background.rgb,
            '--ks-border': location.colorPair.text.rgb,
            '--ks-borderhovertext': location.colorPair.isDark ? 'white' : 'black',
          }"
          size="large"
          @click="reload()"
        >
          {{ t('flashcards.retry') }}
        </KsButton>
      </div>
      <LottieAnimation
        :animation-data="FlashcardAnimation"
        width="250px"
        height="500px"
        class="rotate-180 self-start"
      />
    </div>

    <nav
      class="fixed bottom-6 left-1/2 flex -translate-x-1/2 items-center gap-6 transition-opacity"
      :class="{ 'pointer-events-none opacity-0': activeCardIndex < 0 }"
    >
      <KsButton
        icon-left="arrow-left"
        variant="border"
        shape="round"
        size="large"
        :style="{
          background: location.colorPair.background.rgb,
          '--ks-border': location.colorPair.text.rgb,
          '--ks-borderhovertext': location.colorPair.isDark ? 'white' : 'black',
        }"
        @click="scroll(Dir.Backwards)"
      />
      <div
        class="text-md flex items-center self-stretch rounded px-2 tabular-nums md:fluid-text-xl"
        :style="{ background: location.colorPair.background.rgb }"
      >
        {{ t('flashcards.stage', {
          n: Math.max(1, activeCardIndex + 1),
          total: shuffledCards?.length ?? 0,
        }) }}
      </div>
      <KsButton
        class="transition-[opacity,visibility]"
        :class="{ 'pointer-events-none invisible opacity-0': !isLast }"
        icon-left="arrow-right"
        variant="border"
        shape="round"
        size="large"
        :style="{
          background: location.colorPair.background.rgb,
          '--ks-border': location.colorPair.text.rgb,
          '--ks-borderhovertext': location.colorPair.isDark ? 'white' : 'black',
        }"
        @click="scroll(Dir.Forwards)"
      />
    </nav>
  </div>

  <div
    v-else
    class="grid h-full place-content-center p-6"
  >
    {{ t('flashcards.error') }}
  </div>
</template>
