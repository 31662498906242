<script setup lang="ts">
import type { Group } from '~/models/Group'
import { useI18n } from 'vue-i18n'
import { KsDropdown } from '@aschehoug/kloss'
import useGroupsStore from '~/stores/groups'

const { t } = useI18n()
const { sortedGroups } = useGroupsStore()

const modelValue = defineModel<Group>()
</script>

<template>
  <label class="space-y-2">
    <span
      class="text-base font-medium"
      v-text="t('planner.copyYearPlan.placeholders.chooseGroup')"
    />
    <KsDropdown
      v-model="modelValue"
      :options="sortedGroups"
      :placeholder="t('planner.copyYearPlan.placeholders.chooseGroup')"
      :option-id="(group: Group) => group.groupId"
      :option-label="(group: Group) => group.name"
      :option-value="(group: Group) => group.groupId"
      class="w-full"
    />
  </label>
</template>
