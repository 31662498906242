<script setup lang="ts">
import type { BasicUser } from '~/models/User/BasicUser'
import type { FlytTestAssignment, TestData, TestResult } from '~/models/Assignment'
import { computed } from 'vue'
import { KsExpand, KsExpandItem } from '@aschehoug/kloss'
import ResultGraph from '~/components/reports/ResultGraph.vue'

const props = defineProps<{
  reportData: FlytTestAssignment[]
  user: BasicUser
}>()

const testData = computed((): TestResult[] => {
  const formatedData = props.reportData.map(assignment => {
    const data = parseJson(assignment.responseSummary.find(summary => summary.student.userId === props.user.userId)?.latestAnswer?.data) as TestData

    if (!data) {
      return {
        user: props.user,
        assignmentKey: assignment.assignment.assignmentKey,
        name: assignment.assignment.name,
        correct: 0,
        wrong: 0,
        unanswered: 1,
      }
    }

    let correctAnswers = 0
    let wrongAnswers = 0
    let unansweredAnswers = 0

    data.items?.forEach(item => {
      if (item.score === null) {
        unansweredAnswers++ //@todo: This can suddenly be more than 1...
        return
      }
      correctAnswers += item.score.correctAnswers
      wrongAnswers += item.score.totalQuestions - item.score.correctAnswers
    })

    return {
      user: props.user,
      assignmentKey: assignment.assignment.assignmentKey,
      name: assignment.assignment.name,
      correct: correctAnswers,
      wrong: wrongAnswers,
      unanswered: unansweredAnswers,
    }
  })

  return formatedData.sort((a, b) => {
    const numA = parseInt(a.name.split(' ')[0], 10)
    const numB = parseInt(b.name.split(' ')[0], 10)
    return numA - numB
  })
})

const studentData = computed((): TestResult => {
  const userSummaries = props.reportData
    .flatMap(assignment => { return assignment.responseSummary })
    .filter(summary => summary.student.userId === props.user.userId)

  const missingCount = userSummaries.filter(answer => answer === undefined).length
  //console.log('missingCount: ', missingCount) // @todo: How do we handle this? Is this a problem at all?
  // @todo: Must be addressed, this can cause the wrong %-value of correct since it will be based on the total

  const dataFields = userSummaries.flatMap(summary => {
    return summary.latestAnswer?.data
  })

  const items = dataFields
    .filter(answer => answer !== undefined)
    .map(answer => {
      return JSON.parse(answer)
    })
    .flatMap(data => {
      return data.items
    })

  let correctAnswers = 0
  let wrongAnswers = 0
  let unansweredAnswers = 0

  items.forEach(item => {
    if (item.score === null) {
      unansweredAnswers++ //@todo: This can suddenly be more than 1...
      return
    }
    correctAnswers += item.score.correctAnswers
    wrongAnswers += item.score.totalQuestions - item.score.correctAnswers
  })

  return {
    user: props.user,
    correct: correctAnswers,
    wrong: wrongAnswers,
    unanswered: unansweredAnswers,
  }
})

const studentPercentageScore = computed(() => {
  const correct = studentData.value.correct ?? 0
  const wrong = studentData.value.wrong ?? 0
  const unanswered = studentData.value.unanswered ?? 0
  const total = correct + wrong + unanswered

  if (total === 0) {
    return 0 // Avoid division by zero
  }

  return Math.round((correct / total) * 100)
})

const parseJson = (data: string | undefined): object | null => {
  try {
    return data ? JSON.parse(data) : null
  } catch {
    return null
  }
}
</script>

<template>
  <KsExpand class="student-list-line">
    <KsExpandItem shape="square">
      <template #toggle>
        <div class="mx-auto max-w-screen-au">
          <div class="grid h-14 xs:grid-cols-2">
            <div class="content-center">
              <p class="content-center px-4 text-xl font-medium">
                {{ props.user.fullName }}
              </p>
            </div>
            <div
              ref="wrapper"
              class="flex content-center"
            >
              <p
                ref="part"
                class="content-center pr-4 text-3xl font-medium"
                v-text="`${studentPercentageScore}%`"
              />
              <ResultGraph
                class="w-full"
                :test-result="studentData"
              />
            </div>
          </div>
        </div>
      </template>
      <template #content>
        <ul>
          <li
            v-for="testResult in testData"
            :key="testResult.assignmentKey"
          >
            <div class="mx-auto max-w-screen-au">
              <div class="grid h-14 xs:grid-cols-2">
                <div class="content-center p-4">
                  <p v-text="testResult.name" />
                </div>
                <ResultGraph :test-result="testResult" />
              </div>
            </div>
          </li>
        </ul>
      </template>
    </KsExpandItem>
  </KsExpand>
</template>

<style>
.student-list-line {
  --ks-input: rgba(255, 255, 255, 0);
  --ks-inputhover: rgba(255, 255, 255, 0);

  .ks-expand-toggle > span {
    flex: 1 1
  }
}
</style>
