<script setup lang="ts">
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useQuery } from '@tanstack/vue-query'
import { KsButton } from '@aschehoug/kloss'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useContentApi from '~/api/contentApi'

const { selectedHeader } = defineProps<{
  selectedHeader: ContentProductHeader|ContentProductPackage
}>()

const { t } = useI18n()
const { findContents } = useContentApi()
const { buildResourceUrl } = useUrlBuilder()

const { data: teacherArticle } = useQuery({
  enabled: computed(() => !!selectedHeader?.teacherGuide),
  queryKey: ['teacher-article', selectedHeader?.teacherGuide?.destinationContentId],
  staleTime: Infinity,
  queryFn: () => findContents({
    contentIdCriterion: [Number(selectedHeader?.teacherGuide?.destinationContentId)],
    contentTypeCriterion: [ContentType.TeacherArticle],
  }, 1).then(([article]) => article)
})

const teacherArticleLink = computed(() => teacherArticle.value
  ? buildResourceUrl(teacherArticle.value) : '')
</script>

<template>
  <RouterLink
    v-if="selectedHeader && teacherArticleLink"
    :to="teacherArticleLink"
    class="inline-flex w-max rounded-full focus-visible:ring"
  >
    <KsButton
      variant="secondary"
      size="medium"
      shape="rounded"
      type="label"
      :style="{
        '--ks-input': 'rgb(var(--au-orange-20))',
        '--ks-inputhover': 'rgb(var(--au-orange-10))',
        '--ks-secondary': 'rgb(var(--au-black))',
      }"
    >
      {{ t('header.teacherGuide.teacherGuide') }}
    </KsButton>
  </RouterLink>
</template>
