<script setup lang="ts">
import type { ContentAuthor } from '~/models/Content/ContentAuthor'
import type { ContentArticle } from '~/models/Content/ContentArticle'
import { computed } from 'vue'
import { createReusableTemplate } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import Image from '~/components/media/Image.vue'
import ArticleHeaderBar from '~/components/article/ArticleHeaderBar.vue'

const { article } = defineProps<{
  article: ContentArticle
}>()

const { findContents } = useContentApi()
const [DefineHeaderContent, ReuseHeaderContent] = createReusableTemplate()

const { data: authors } = useQuery({
  staleTime: Infinity,
  queryKey: ['app-article-authors', article.locationId],
  queryFn() {
    if (!article.authors?.destinationContentIds?.length) return null
    return findContents<ContentAuthor>({
      contentIdCriterion: article.authors.destinationContentIds,
      contentTypeCriterion: [ContentType.Author],
      subtreeCriterion: [Subtree.Authors],
      mainLocationCriterion: true,
    })
  },
  enabled: computed(() => (article.authors?.destinationContentIds.length ?? 0) > 0)
})
</script>
<template>
  <!-- Header -->
  <DefineHeaderContent>
    <div
      v-if="article.overline"
      class="font-semibold uppercase"
    >
      {{ article.overline }}
    </div>
    <h1 class="au-title font-semibold">
      {{ article.title }}
    </h1>
    <div class="au-subtitle au-prose">
      <RichTextRenderer :text="article.body" />
    </div>
    <div
      v-if="authors"
      class="mt-auto"
    >
      {{ $t('article.text') }}:
      <strong
        v-for="(author, index) in authors"
        :key="author.contentId"
      >
        {{ author.title }}<template v-if="index + 1 < authors.length">, </template>
      </strong>
    </div>
  </DefineHeaderContent>

  <header
    v-if="article.headerVariation.identifier === 'text_on_image'"
    class="col-[fill] grid grid-flow-row grid-cols-subgrid grid-rows-[50vh] gap-y-6"
  >
    <Image
      v-if="article.image"
      class="col-start-1 -col-end-1 row-start-1 row-end-1 !size-full min-h-full object-cover"
      :content="Number(article.image.destinationContentId)"
    />
    <div class="col-[extext] row-start-1 grid grid-cols-subgrid self-end rounded-t-lg bg-[--color-lighter] pt-[--col-width]">
      <div
        v-if="article.overline"
        class="col-[text] font-semibold uppercase"
      >
        {{ article.overline }}
      </div>
      <h1 class="au-title col-[text] font-semibold">
        {{ article.title }}
      </h1>
    </div>
    <div class="col-[text] flex flex-col gap-6">
      <div class="au-subtitle au-prose contents">
        <RichTextRenderer :text="article.body" />
      </div>
      <div
        v-if="authors"
        class="mt-auto"
      >
        {{ $t('article.text') }}:
        <strong
          v-for="(author, index) in authors"
          :key="author.contentId"
        >
          {{ author.title }}<template v-if="index + 1 < authors.length">, </template>
        </strong>
      </div>
      <ArticleHeaderBar :article="article" />
    </div>
  </header>

  <template v-else-if="article.headerVariation.identifier === 'text_and_image'">
    <header class="col-[media] mt-24 grid place-items-stretch text-[--color-pop] md:grid-cols-2">
      <div class="flex flex-col gap-4 bg-[--color-darker] p-[--col-width] max-md:order-2 max-md:rounded-b-lg md:rounded-l-lg">
        <ReuseHeaderContent />
      </div>
      <Image
        v-if="article.image"
        class="!w-full bg-[--color-light] object-cover p-[--col-gap] max-md:order-1 max-md:rounded-t-lg md:rounded-r-lg"
        width="50vw"
        :content="Number(article.image.destinationContentId)"
      />
      <ArticleHeaderBar
        class="order-3 col-[1/-1] mt-4"
        :article="article"
      />
    </header>
  </template>

  <header
    v-else-if="article.headerVariation.identifier === 'color'"
    class="col-start-1 -col-end-1 grid grid-cols-subgrid bg-[--color-darker] pb-[--col-width] pt-44 text-[--color-pop]"
  >
    <div class="col-[text] flex flex-col gap-4 ">
      <ReuseHeaderContent />
      <ArticleHeaderBar :article="article" />
    </div>
  </header>

  <header
    v-else-if="article.headerVariation.identifier === 'simple'"
    class="col-[text] flex flex-col gap-4 pt-44"
  >
    <ReuseHeaderContent />
    <ArticleHeaderBar :article="article" />
  </header>

  <header
    v-else
    class="col-start-1 -col-end-1 pt-44"
  >
    <h1 class="sr-only">
      {{ article.title }}
    </h1>
  </header>
</template>
