import type { Task, TaskQueryParams, TaskStatus, TaskUser, TaskUserRequest } from '~/models/AssignTask'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import { externalClient } from '~/api/client/externalClient'

export const useTasksApi = () => {
  const { userId } = storeToRefs(useAuthStore())

  const buildQuery = (params: TaskQueryParams): string => {
    const queryString = Object.entries(params)
      .filter(([_, value]) => value && value.length > 0)
      .map(([key, value]) => `${key}=${encodeURIComponent((value as string[]).join(','))}`)
      .join('&')

    return queryString ? `?${queryString}` : ''
  }

  return {
    getTasks: async (filters: TaskQueryParams = {}): Promise<Task[]> => {
      const query = buildQuery({ ...filters })
      return (await externalClient.get<Task[]>(`/frontend/tasks${query}`)).data
    },

    createTask: async (task: Partial<Task>): Promise<Task> =>
      (await externalClient.post('/frontend/tasks', task)).data,

    updateTask: async (task: Task): Promise<Task> =>
      (await externalClient.post(`/frontend/tasks/${task.taskId}?userId=${userId.value}`, task)).data,

    deleteTask: async (task: Task): Promise<void> =>
      (await externalClient.delete(`/frontend/tasks/${task.taskId}`)).data,

    closeTask: async (task: Task): Promise<void> =>
      await externalClient.patch(`/frontend/tasks/${task.taskId}/close`),

    openTask: async (task: Task): Promise<void> =>
      await externalClient.patch(`/frontend/tasks/${task.taskId}/open`),

    getTaskUsers: async (task: Task): Promise<TaskUser[]> =>
      (await externalClient.get<TaskUser[]>(`/frontend/tasks/${task.taskId}/users`)).data,

    addTaskUsers: async (task: Task, users: TaskUserRequest): Promise<Task> =>
      (await externalClient.put<Task>(`/frontend/tasks/${task.taskId}/users`, users)).data,

    updateTaskUser: async (task: Task, user: TaskUser, status: TaskStatus): Promise<void> =>
      await externalClient.patch(`/frontend/tasks/${task.taskId}/users/${user.userId}?status=${status}`),
  }
}
