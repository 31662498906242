<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { KsButton } from '@aschehoug/kloss'
import { waitFor } from '~/utils/asyncUtils'
import useProductStore from '~/stores/product'
import useLicenseControlStore from '~/stores/licenseControl'
import { useAuthStore } from '~/stores/auth'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useFullScreen from '~/composables/useFullscreen'
import useContentApi from '~/api/contentApi'

const { t } = useI18n()
const router = useRouter()
const { isSupported, isFullscreen, onFullscreenChange, setCurrentFullscreenState } = useFullScreen()
const { hasAccessRestricted } = storeToRefs(useLicenseControlStore())
const { buildResourceUrl } = useUrlBuilder()
const { isAuthenticated } = storeToRefs(useAuthStore())
const { hasProducts } = storeToRefs(useProductStore())
const { getProductFromResource } = useProductStore()
const { findContents } = useContentApi()

const props = withDefaults(defineProps<{
  override?: RouteLocationRaw
  text?: string
  classes?: string | string[]
  variant?: string
  shape?: string
  iconLeft?: string
  size?: string
  item?: BaseItem
  isFixed?: boolean
}>(), {
  classes: () => [],
  variant: 'border',
  shape: 'round',
  iconLeft: 'xmark',
  size: 'large',
  isFixed: true,
})

const homeRoute = router.resolve({ name: 'home' })
const closeRoute = ref<RouteLocationRaw>(homeRoute)
const previousRoute = router.currentRoute.value.meta.returnPath
const currentRoute = router.currentRoute.value.fullPath

const hideCloseButton = computed(() => hasAccessRestricted.value && props.item)
const hasPreviousRoute = computed(() => previousRoute && previousRoute !== currentRoute)

const product = computed(() => props.item && getProductFromResource(props.item))

const resolveCloseRoute = async (): Promise<RouteLocationRaw> => {
  if (props.override) return props.override
  if (hasPreviousRoute.value) return previousRoute
  if (!props.item?.parentLocationId) return homeRoute
  if (!product.value) return previousRoute || homeRoute

  try {
    const [parents] = await Promise.all([
      findContents({ locationIdCriterion: [props.item.parentLocationId] }),
      waitFor(() => hasProducts.value, 5000)
    ])

    if (!parents.length) return homeRoute
    const parentUrl = buildResourceUrl(parents[0])
    return router.resolve(parentUrl).name === 'generic'
      ? homeRoute
      : parentUrl
  } catch (e) {
    console.error(e)
  }
  return homeRoute
}

onMounted(async () => {
  if (!isAuthenticated.value) return
  if (isSupported()) {
    addEventListener('fullscreenchange', onFullscreenChange)
    setCurrentFullscreenState()
  }
  closeRoute.value = await resolveCloseRoute()
})

onUnmounted(() => {
  if (isSupported()) {
    removeEventListener('fullscreenchange', onFullscreenChange)
  }
})
</script>

<template>
  <KsButton
    v-if="isAuthenticated && !hideCloseButton && !isFullscreen"
    :class="[...classes, ...(isFixed ? ['fixed', 'top-4', 'right-4'] : [])]"
    class="z-10 transition duration-200"
    :size="props.size"
    :variant="props.variant"
    :shape="props.shape"
    :aria-label="t('close')"
    :icon-left="props.iconLeft"
    @click="router.push(closeRoute)"
  >
    {{ !!props.text ? text : '' }}
  </KsButton>
</template>
