<script setup lang="ts">
import type { Task } from '~/models/AssignTask'
import { ref } from 'vue'
import { faEdit, faEye, faLock } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsDialog, KsIcon, KsSpinner } from '@aschehoug/kloss'
import TeleportationTarget from '~/models/TeleportationTarget'
import { TaskStatus } from '~/models/AssignTask'
import { useAssignTask } from '~/composables/useAssignTask'
import StudentEditList from '~/components/products/exams/assignment/StudentEditList.vue'
import DeleteTaskDialog from '~/components/products/exams/assignment/DeleteTaskDialog.vue'

const { task } = defineProps<{ task: Task }>()

const { closeTask, openTask } = useAssignTask()

const open = ref(false)
</script>

<template>
  <KsButton
    variant="secondary"
    :icon-left="faEdit"
    size="small"
    @click="open = true"
  >
    Rediger elevtilgang
  </KsButton>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :open
      :title="task.name"
      size="small"
      @close="open = false"
    >
      <template #body>
        <div class="flex flex-col gap-4">
          <div
            class="flex size-full gap-2 space-y-1 rounded-md border-2 px-3 py-2"
            :class="task.status === TaskStatus.Open ? 'border-green-20 bg-green-5 text-green-50' : 'border-coral-30 bg-coral-5 text-coral-50'"
          >
            <KsIcon
              :icon="task.status === TaskStatus.Open ? faEye : faLock"
              class="mt-1.5"
            />
            <div>
              <p class="font-semibold">
                {{ task.status === TaskStatus.Open ? 'Terminprøven er åpen' : 'Terminprøven er låst' }}
              </p>
              <p class="text-sm font-medium">
                For at eleven skal få tilgang til terminprøven må prøven og elevens status være satt til åpen.
              </p>
            </div>
          </div>
          <StudentEditList :task />
        </div>
      </template>
      <template #footer>
        <div class="flex items-center justify-end gap-4">
          <DeleteTaskDialog
            :task
            :disable-btn="closeTask.isPending.value || openTask.isPending.value"
          />
          <KsSpinner
            v-if="closeTask.isPending.value || openTask.isPending.value"
            size="small"
          />
          <KsButton
            v-if="task.status === TaskStatus.Open"
            variant="primary"
            shape="rounded"
            :disabled="closeTask.isPending.value"
            :style="{
              '--ks-primary': 'rgb(var(--au-coral-50))',
              '--ks-secondary': 'rgb(var(--au-coral-40))'
            }"
            @click="closeTask.mutateAsync(task)"
          >
            Steng prøven for alle
          </KsButton>
          <KsButton
            v-else
            variant="primary"
            :disabled="openTask.isPending.value"
            shape="rounded"
            @click="openTask.mutateAsync(task)"
          >
            Åpne prøven
          </KsButton>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
