<script setup lang="ts">
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import { computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsButton } from '@aschehoug/kloss'
import { firstOf } from '~/utils/queryUtils'
import { setTitle } from '~/utils/dom'
import useProductStore from '~/stores/product'
import useAuthStore from '~/stores/auth'
import { PendoFeature } from '~/models/Pendo'
import { ContentType } from '~/models/Content/ContentType'
import { ColorTheme } from '~/models/Content/Color'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useMedia } from '~/composables/useMedia'
import { useColorTheme } from '~/composables/useColorTheme'
import useCardStyles from '~/composables/useCardStyles'
import { useAppColor } from '~/composables/useAppColor'
import useContentApi from '~/api/contentApi'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import HeaderViewBannerGrid from '~/components/utils/HeaderViewBannerGrid.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import HeaderTeacherButton from '~/components/subject/HeaderTeacherButton.vue'
import LowerSecondaryHeaderSection from '~/components/subject/gradetypes/LowerSecondaryHeaderSection.vue'
import HeaderViewSkeleton from '~/components/skeletons/HeaderViewSkeleton.vue'
import Image from '~/components/media/Image.vue'

const { locationId } = defineProps<{ locationId: number }>()

const { t } = useI18n()
const { findContents } = useContentApi()
const { getProductFromResource } = useProductStore()
const { resolveBaseUrl } = useUrlBuilder()
const { set: setAppColor } = useAppColor()
const { theme } = useColorTheme(ColorTheme.BlueOrange)
const { isTeacher } = storeToRefs(useAuthStore())
const { cardStyle, themeStyle } = useCardStyles(theme)

const { data, isLoading } = useQuery({
  queryKey: computed(() => ['task-header', locationId]),
  queryFn: async () => {
    const [task, headers] = await Promise.all([
      (await findContents<ContentProductPackage>({
        locationIdCriterion: [locationId],
        contentTypeCriterion: [ContentType.ProductPackage],
        mainLocationCriterion: true,
      }, 1))[0],
      findContents<ContentProductHeader>({
        parentLocationIdCriterion: [locationId],
        contentTypeCriterion: [ContentType.ProductHeader],
        sortField: 'priority',
        sortOrder: 'asc',
      }),
    ])
    return { task, headers }
  },
  enabled: computed(() => !!locationId),
  staleTime: Infinity,
})

const mediaContentId = computed(() => Number(data?.value?.task?.image?.destinationContentId))
const product = computed(() => data.value?.task && getProductFromResource(data.value?.task))
const productLink = computed(() => product.value ? resolveBaseUrl(product.value) : '')
const showTeacherButton = computed(() => isTeacher.value && data.value?.task)

const { data: bgMedia, isLoading: bgMediaIsLoading } = firstOf(useMedia(mediaContentId.value ? [mediaContentId.value] : []))

onMounted(() => setAppColor({
  themeColor: theme.value[60].name,
  bgColor: theme.value[10].name,
}))

watch(data, () => {
  if (!data.value?.task) return
  setTitle(t('termExam.byline', { title: data.value.task.title.toLowerCase() }))
}, { immediate: true })
</script>

<template>
  <div
    class="grid gap-20 text-[--theme-60]"
    :style="themeStyle"
  >
    <template v-if="!isLoading">
      <HeaderViewBannerGrid class="bg-[--theme-60] text-[--theme-10]">
        <router-link
          v-if="product && !!productLink"
          :to="{ path: productLink }"
          class="max-w-max pt-24 grid-in-filters"
        >
          <template #default="{ navigate }">
            <KsButton
              variant="secondary"
              icon-left="arrow-left"
              @click="navigate"
            >
              {{ product.aunivers.name }}
            </KsButton>
          </template>
        </router-link>
        <div class="flex flex-col items-start gap-4 self-center pb-24 pr-9 grid-in-header-info">
          <span
            class="uppercase tracking-wider"
            v-text="t('termExam.byline', { title: data?.task?.parentLocationName || '' })"
          />
          <h1
            class="text-4xl font-bold"
            v-text="data?.task?.title"
          />
          <RichTextRenderer
            v-if="data?.task?.intro"
            class="prose text-xl text-[currentColor]"
            :text="data.task.intro"
          />
          <HeaderTeacherButton
            v-if="showTeacherButton"
            :selected-header="data?.task"
            :data-pendo="PendoFeature.TeacherContent.Header"
          />
        </div>
        <Image
          v-if="mediaContentId"
          :content="mediaContentId"
          class="aspect-video !h-auto min-h-full w-full max-w-full place-self-end object-cover object-left grid-in-image md:h-0 md:grid-in-[image-top_/_image-top_/_image]"
          :class="!bgMediaIsLoading && 'animate-fade'"
          width="700px"
        />
        <CopyrightButton
          v-if="bgMedia && 'copyright' in bgMedia"
          :copyright="bgMedia.copyright"
          class="row-start-2 m-4 place-self-end col-end-image"
        />
      </HeaderViewBannerGrid>
      <section
        v-for="(header, index) in data?.headers"
        :key="header.contentId"
        class="grid grid-cols-1 place-items-center last:pb-20"
        :class="{ 'bg-[--theme-20] py-20 [.py-20+&]:-mt-20 [.py-20+&]:pt-0': header.viewMode === 'task' }"
        :style="cardStyle(header, index)"
      >
        <div class="flex w-full max-w-screen-au flex-col gap-24 px-4 sm:px-8">
          <LowerSecondaryHeaderSection
            :header
            :index
            :grade-filter="false"
          />
        </div>
      </section>
    </template>
    <HeaderViewSkeleton v-else />
  </div>
</template>
