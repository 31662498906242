import type { TeacherArticle, TeacherArticleContent } from '~/models/TeacherArticle'
import type { SubjectCode } from '~/models/Subject'
import type { GridPage } from '~/models/Presentation/Pages/GridPage'
import type { PresentationPage } from '~/models/Presentation/BasePage'
import type { PlannedItem } from '~/models/Content/PlannedItem'
import type { ContentTermExam } from '~/models/Content/ContentTermExam'
import type { ContentResource } from '~/models/Content/ContentResource'
import type { ContentProductPart } from '~/models/Content/ContentProductPart'
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import type { ContentProductArticle } from '~/models/Content/ContentProductArticle'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import type { ContentPdfBook } from '~/models/Content/ContentPdfBook'
import type { ContentPackage } from '~/models/Content/ContentPackage'
import type { ContentModelText } from '~/models/Content/ContentModelText'
import type { ContentLink } from '~/models/Content/ContentLink'
import type { ContentLearningPath } from '~/models/Content/ContentLearningPath'
import type { ContentImageCollection } from '~/models/Content/ContentImageCollection'
import type { ContentFlytTask } from '~/models/Content/ContentFlytTask'
import type { ContentFlashcardDeck } from '~/models/Content/ContentFlashcards'
import type { ContentFile } from '~/models/Content/ContentFile'
import type { ContentChatbot } from '~/models/Content/ContentChatbot'
import type { ContentBookReader } from '~/models/Content/ContentBookReader'
import type { ContentArticle } from '~/models/Content/ContentArticle'
import type { BaseItem } from '~/models/Content/BaseItem'
import type { MetadataField } from '~/models/Content/BaseField'
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { isUuidIdentifier } from '~/utils/uuidUtils'
import useProductStore from '~/stores/product'
import { Section } from '~/models/Content/Section'
import { ContentType } from '~/models/Content/ContentType'

export function useContentHelper() {
  const getParentLocationId = (content: BaseItem) => {
    let pathString = content.pathString || ''
    if (pathString.endsWith('/')) {
      pathString = pathString.slice(0, -1)
    }
    const pathArray = pathString.split('/')
    if (pathArray.length < 2) return 0
    const parentLocationId = pathArray[pathArray.length - 2]
    return parseInt(parentLocationId, 10)
  }

  const findSubjectFromContent = (content: BaseItem): Nullable<SubjectCode> => {
    const { products } = useProductStore()
    return products.find((product) => {
      // look in the pathString for content for the locationId of this product on the form /{locationId}/
      return content.pathString.includes(`/${product.aunivers.locationId}/`)
    })?.subjects[0]
  }

  const isProduct = (item: BaseItem | ContentProduct): item is ContentProduct =>
    item.contentTypeIdentifier === ContentType.Product

  const packageTypes = [
    ContentType.ProductHeader,
    ContentType.ProductPackage,
    ContentType.ProductContainer,
    ContentType.Folder,
  ]

  const isPackage = (item: BaseItem): item is ContentPackage =>
    item.contentTypeIdentifier && packageTypes.includes(item.contentTypeIdentifier)

  const isFile = (item: BaseItem | ContentFile): item is ContentFile =>
    item.contentTypeIdentifier === ContentType.File && item.sectionId !== Section.AsBook

  const isHeader = (item: BaseItem): item is ContentPackage | ContentProduct => isPackage(item) || isProduct(item)

  const isContentHeader = (item: BaseItem): item is ContentProductHeader =>
    item.contentTypeIdentifier === ContentType.ProductHeader

  const isContentPackage = (item: BaseItem): item is ContentProductPackage =>
    item.contentTypeIdentifier === ContentType.ProductPackage

  const isUploadedFile = (item: BaseItem): item is PlannedItem =>
    item?.contentTypeIdentifier === ContentType.UploadedFile

  const isLink = (item: BaseItem): item is ContentLink =>
    item?.contentTypeIdentifier === ContentType.Link

  const isLearningPath = (item: BaseItem): item is ContentLearningPath =>
    item?.contentTypeIdentifier === ContentType.LearningPath

  const isProductPart = (item: BaseItem): item is ContentProductPart =>
    item?.contentTypeIdentifier === ContentType.ProductPart

  const isProductArticle = (item: BaseItem): item is ContentProductArticle =>
    item?.contentTypeIdentifier === ContentType.ProductArticle

  const isBookReader = (item: BaseItem): item is ContentBookReader =>
    item?.contentTypeIdentifier === ContentType.BookReader

  const isFlashcards = (item: BaseItem): item is ContentFlashcardDeck =>
    item?.contentTypeIdentifier === ContentType.Flashcards

  const isPlannedItem = (item: BaseItem | PlannedItem): item is PlannedItem =>
    Boolean((item as PlannedItem)?.identifier)
    && isUuidIdentifier((item as PlannedItem)?.identifier)

  const isContentResource = (item: BaseItem | ContentResource): item is ContentResource =>
    Boolean((item as ContentResource)?.identifier)
    && !isUuidIdentifier((item as ContentResource).identifier)

  const getTitle = (item: BaseItem) => item?.shortTitle || item.title || ''

  const isPresentation = (item: BaseItem) =>
    item?.contentTypeIdentifier === ContentType.Presentation

  const isVideo = (item: BaseItem) =>
    item?.contentTypeIdentifier === ContentType.Video

  const isGridPage = (item: PresentationPage): item is GridPage =>
    item.contentTypeIdentifier === ContentType.GridPage

  const isFlytTask = (item: BaseItem | ContentFlytTask): item is ContentFlytTask =>
    item.contentTypeIdentifier === ContentType.FlytTask

  const isArticle = (item: BaseItem | ContentArticle): item is ContentArticle =>
    item?.contentTypeIdentifier === ContentType.Article

  const isPdfBook = (item: BaseItem | ContentPdfBook): item is ContentPdfBook =>
    item.contentTypeIdentifier === ContentType.PdfBook

  const isPdf = (item: ContentFile | ContentPdfBook) => {
    if (isFile(item)) return item.file?.mimeType === 'application/pdf'
    if (isPdfBook(item)) return item.metadata?.mimeType === 'application/pdf'
    return false
  }

  const isPlaylist = (content: { metadata?: MetadataField }) => content.metadata?.docType === 'ContentPlayList'

  const isTeacherArticle = (item: TeacherArticle | TeacherArticleContent | BaseItem): item is TeacherArticle =>
    item.contentTypeIdentifier === ContentType.TeacherArticle

  const isArticleContent = (item: BaseItem | ArticleContent): item is ArticleContent =>
    item.contentTypeIdentifier === ContentType.ArticleContent

  const isArticleTabs = (item: BaseItem | ArticleContent): item is ArticleContent =>
    item.contentTypeIdentifier === ContentType.ArticleTabs

  const isImageCollection = (item: BaseItem | ContentImageCollection): item is ContentImageCollection =>
    item.contentTypeIdentifier === ContentType.ImageCollection

  const isModelText = (item: BaseItem | ContentImageCollection): item is ContentModelText =>
    item.contentTypeIdentifier === ContentType.ModelText

  const isChatbot = (item: BaseItem|ContentChatbot): item is ContentChatbot =>
    item.contentTypeIdentifier === ContentType.Chatbot

  const isTermExam = (item: BaseItem | ContentTermExam): item is ContentTermExam =>
    item.contentTypeIdentifier === ContentType.TermExam

  return {
    getParentLocationId,
    findSubjectFromContent,
    packageTypes,
    isPackage,
    isProduct,
    isHeader,
    isContentHeader,
    isFile,
    isUploadedFile,
    isLink,
    isLearningPath,
    isProductArticle,
    isProductPart,
    isBookReader,
    isFlashcards,
    isArticle,
    isPresentation,
    isVideo,
    getTitle,
    isPlannedItem,
    isGridPage,
    isContentResource,
    isFlytTask,
    isPdfBook,
    isPdf,
    isPlaylist,
    isTeacherArticle,
    isArticleContent,
    isArticleTabs,
    isImageCollection,
    isModelText,
    isChatbot,
    isContentPackage,
    isTermExam
  }
}
