<script setup lang="ts">
import type { Task } from '~/models/AssignTask'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { faEye, faLock } from '@fortawesome/pro-solid-svg-icons'
import { KsButton, KsIcon } from '@aschehoug/kloss'
import useGroupsStore from '~/stores/groups'
import useAuthStore from '~/stores/auth'
import { TaskStatus } from '~/models/AssignTask'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useGradeString from '~/composables/useGradeString'
import EditAssignTaskDialog from '~/components/products/exams/assignment/EditAssignTaskDialog.vue'
import AssignStudentsDialog from '~/components/products/exams/assignment/AssignStudentsDialog.vue'

const { task } = defineProps<{ task: Task }>()

const { t } = useI18n()
const { findGroupById } = useGroupsStore()
const { createGradeString } = useGradeString()
const { isTeacher, isStudent } = storeToRefs(useAuthStore())
const { buildAssignedTaskUrl } = useUrlBuilder()

const groupName = computed(() => {
  if (!task.groups?.length) return
  return findGroupById(task.groups[0])?.name
})

const subtitle = computed(() => {
  const grades = task.grades ? createGradeString(task.grades) : ''

  return [grades, t('termExam.title')].filter(Boolean).join(' | ')
})

const taskIsOpen = computed(() => task.status === TaskStatus.Open)
</script>

<template>
  <article class="group relative flex size-full items-center justify-center gap-2 rounded-md bg-white p-2 transition">
    <div
      class="flex size-14 flex-col items-center justify-center gap-1 rounded-l-md"
      :class="taskIsOpen ? 'bg-green-5' : 'bg-coral-5'"
    >
      <KsIcon
        :class="taskIsOpen ? 'text-green-40' : 'text-coral-40'"
        :icon="taskIsOpen ? faEye : faLock"
        :beat="taskIsOpen"
        loop-animation
        animation-duration="2500ms"
      />
      <span
        class="text-xs font-semibold"
        :class="taskIsOpen ? 'text-green-50' : 'text-coral-50'"
      >
        {{ taskIsOpen ? 'Åpen' : 'Låst' }}
      </span>
    </div>
    <div class="flex">
      <div
        class="flex flex-col gap-1"
        :class="groupName && 'border-r border-blue-10 pr-4'"
      >
        <p
          class="text-xs font-medium uppercase tracking-wider text-gray-40"
          v-text="subtitle"
        />
        <h3
          class="font-bold text-gray-50"
          v-text="task.name"
        />
      </div>
      <div
        v-if="groupName"
        class="flex flex-col gap-1.5 pl-4"
      >
        <span class="text-xs font-medium uppercase tracking-wider text-gray-40">Gruppenavn:</span>
        <p
          class="text-sm font-medium text-gray-50"
          v-text="groupName"
        />
      </div>
    </div>
    <div
      v-if="isTeacher"
      class="ml-auto"
    >
      <AssignStudentsDialog
        v-if="task.users?.length === 0"
        :task
      />
      <EditAssignTaskDialog
        v-else
        :task
      />
    </div>
    <RouterLink
      v-if="isStudent"
      :to="buildAssignedTaskUrl(task)"
      class="ml-auto inline-flex rounded-xl focus-visible:ring"
    >
      <KsButton
        variant="primary"
        type="label"
        icon-right="arrow-right"
      >
        Gå til prøven
      </KsButton>
    </RouterLink>
  </article>
</template>
