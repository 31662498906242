import type { Task } from '~/models/AssignTask'
import { inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useQueryClient } from '@tanstack/vue-query'
import { useAuthStore } from '~/stores/auth'
import useSlugify from '~/composables/useSlugify'
import { useSseApi } from '~/api/sseApi'

const { slugify } = useSlugify()

export function useTaskSseListener() {
  const { subscribeStream } = useSseApi()
  const { userId } = storeToRefs(useAuthStore())
  const queryClient = useQueryClient()
  const router = useRouter()
  const eventSourceInstance = ref<EventSource | null>(null)
  const currentStreamId = ref<string | null>(null)
  const toast = inject<Toast>('ksToast')
  const processedMessages = ref<Set<string>>(new Set())
  const subscribedTaskIds = ref<Set<string>>(new Set())

  const subscribeToStream = (streamId: string) => {
    if (eventSourceInstance.value && currentStreamId.value === streamId) {
      return // Already subscribed to this streamId
    }

    eventSourceInstance.value = subscribeStream(streamId)
    currentStreamId.value = streamId

    eventSourceInstance.value.onmessage = async (event: MessageEvent) => {
      if (event.data === 'keep-alive') return
      const message = JSON.parse(event.data)

      // Prevent processing duplicate messages based on the unique ID
      if (processedMessages.value.has(message.id)) return
      processedMessages.value.add(message.id)

      // Proceed only if message is relevant for the user
      if (message.all || message.userId === userId.value) {
        await queryClient.invalidateQueries()
      }
    }

    eventSourceInstance.value.onerror = (err: Event) => {
      console.error('Error with SSE stream:', err)
    }
  }

  const subscribeToTasks = (tasks: Task[]) => {
    tasks.forEach(task => {
      const taskId = String(task.taskId)
      if (!subscribedTaskIds.value.has(taskId)) {
        subscribeToStream(taskId)
        subscribedTaskIds.value.add(taskId)
      }
    })
  }

  return {
    subscribeToStream,
    subscribeToTasks,
    subscribedTaskIds,
  }
}
