import type { ContentModelText } from '~/models/Content/ContentModelText'
import type { ContentImageCollection } from '~/models/Content/ContentImageCollection'
import type { ContentFlytTask } from '~/models/Content/ContentFlytTask'
import type { ContentFlashcardDeck } from '~/models/Content/ContentFlashcards'
import type { ContentArticleTabs } from '~/models/Content/ContentArticleTabs'
import type { BaseItem } from '~/models/Content/BaseItem'
import type { RelationField, RelationsField, TaxonomyField } from '~/models/Content/BaseField'
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { ContentType } from '~/models/Content/ContentType'

export enum ArticleSize {
  Medium = 'medium',
  Large = 'large',
  XLarge = 'xlarge',
}

export enum ArticleHeaderVariation {
  Simple = 'simple',
  Color = 'color',
  TextAndImage = 'text_and_image',
  TextOnImage = 'text_on_image',
}

export enum ArticleColorTheme {
  Violet = 'violet',
  Blue = 'blue',
  Green = 'green',
  Coral = 'coral',
}

export const ArticleContentTypeCriterion = [
  ContentType.ArticleContent,
  ContentType.ArticleTabs,
  ContentType.ImageCollection,
  ContentType.FlytTask,
  ContentType.Flashcards,
  ContentType.ModelText,
]

export type ArticleContentTypes =
  ArticleContent |
  ContentArticleTabs |
  ContentImageCollection |
  ContentFlytTask |
  ContentFlashcardDeck |
  ContentModelText

export interface ContentArticle extends BaseItem {
  overline?: string
  body?: string
  activity?: string
  wordlist: string[]
  size: TaxonomyField<ArticleSize>
  image?: RelationField
  authors?: RelationsField
  content?: RelationsField
  colorTheme: TaxonomyField<ArticleColorTheme>
  headerVariation: TaxonomyField<ArticleHeaderVariation>
  studentGuide: string
  teacherContent?: RelationField
}
