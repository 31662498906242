<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import type { ContentTermExam } from '~/models/Content/ContentTermExam'
import type { ContentProductPackage } from '~/models/Content/ContentProductPackage'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import useContentApi from '~/api/contentApi'
import StackedCard from '~/components/cards/StackedCard.vue'
import ExamsCard from '~/components/cards/ExamsCard.vue'

const { exams, selectedGrades, isLoading } = defineProps<{
  exams: ContentTermExam
  selectedGrades: GradeCode[]
  isLoading: boolean
}>()

const { findContents } = useContentApi()

const { data, isLoading: isLoadingResources } = useQuery({
  queryKey: computed(() => ['new_exams_resources', exams.locationId, selectedGrades]),
  queryFn: () => findContents<ContentProductPackage>({
    parentLocationIdCriterion: [exams.locationId],
    gradeFieldCriterion: selectedGrades,
    sortField: exams.sortField,
    sortOrder: exams.sortOrder,
    gradeOperator: 'or',
  }),
  enabled: computed(() => !!exams.locationId),
  staleTime: Infinity,
})

const resources = computed(() => data.value || [])
</script>

<template>
  <section
    v-if="!isLoading && !isLoadingResources"
    class="space-y-2"
  >
    <h2
      class="text-2xl font-bold"
      v-text="exams.title"
    />
    <ul class="grid grid-cols-1 gap-4 xs:grid-cols-2 lg:grid-cols-3">
      <StackedCard
        v-for="resource in resources"
        :key="resource.contentId"
        :item="resource"
        class="size-full"
        :depth="2"
        element="div"
        :stack-offset="2"
        padded-stack
        :colors="['--au-blue-10']"
      >
        <template #default="{ item }">
          <ExamsCard
            v-if="item"
            :resource="item"
          />
        </template>
      </StackedCard>
    </ul>
  </section>
  <KsSkeletonWrapper v-else>
    <KsSkeleton
      height="30px"
      width="150px"
    />
    <ul class="mt-2 grid grid-cols-1 gap-4 xs:grid-cols-2 lg:grid-cols-3">
      <KsSkeleton
        v-for="i in 3"
        :key="i"
        height="250px"
      />
    </ul>
  </KsSkeletonWrapper>
</template>
