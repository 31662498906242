<script setup lang="ts">
import type { Task, TaskUser } from '~/models/AssignTask'
import { computed, ref } from 'vue'
import { faEye, faLock } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsDialog, KsIcon } from '@aschehoug/kloss'
import TeleportationTarget from '~/models/TeleportationTarget'
import { TaskStatus } from '~/models/AssignTask'
import { useAssignTask } from '~/composables/useAssignTask'

const { student, task } = defineProps<{ student: TaskUser; task: Task }>()

const { updateTaskUser } = useAssignTask()

const confirmDialogOpen = ref(false)

const statusIsOpen = computed(() => student.status === TaskStatus.Open)
const buttonColor = computed(() => statusIsOpen.value && {
  '--ks-primary': 'rgb(var(--au-coral-50))',
  '--ks-secondary': 'rgb(var(--au-coral-40))'
})
const buttonText = computed(() => {
  if (updateTaskUser.isPending.value) {
    return !statusIsOpen.value ? 'Stenger prøven' : 'Åpner prøven'
  }
  return statusIsOpen.value ? 'Steng prøven' : 'Åpne prøven'
})

const updateStudent = () => {
  updateTaskUser.mutateAsync({
    task: task,
    user: student,
    status: statusIsOpen.value ? TaskStatus.Closed : TaskStatus.Open
  })
  confirmDialogOpen.value = false
}
</script>

<template>
  <li class="relative flex size-full items-center justify-between border-b border-green-10 bg-secondary/30 px-1 py-2 font-medium last:border-b-0">
    <div class="flex items-center gap-4">
      <div
        class="flex items-center justify-center rounded-lg p-1 text-base transition-all"
        :class="statusIsOpen ? 'bg-green-10 text-green-50' : 'bg-coral-20 text-coral-50'"
      >
        <KsIcon
          :icon="statusIsOpen ? faEye : faLock"
          :class="statusIsOpen ? 'text-green-50' : 'text-coral-50'"
        />
      </div>
      <span v-text="student.fullName" />
    </div>
    <KsButton
      size="small"
      :variant="statusIsOpen ? 'secondary' : 'primary'"
      @click="confirmDialogOpen = true"
    >
      {{ statusIsOpen ? 'Lukk' : 'Åpne' }}
    </KsButton>
    <Teleport :to="TeleportationTarget.AppTop">
      <KsDialog
        :open="confirmDialogOpen"
        title="Bekreft valg"
        @close="confirmDialogOpen = false"
      >
        <template #body>
          <div class="">
            <p class="font-medium">
              Er du sikker på at du vil {{ statusIsOpen ? 'stenge' : 'åpne' }} terminprøven for <span class="font-bold">{{ student.fullName }}</span>?
            </p>
          </div>
        </template>
        <template #footer>
          <div class="flex items-center justify-center gap-4">
            <KsButton
              variant="secondary"
              shape="rounded"
              @click="confirmDialogOpen = false"
            >
              Avbryt
            </KsButton>
            <KsButton
              variant="primary"
              :style="buttonColor"
              shape="rounded"
              @click="updateStudent"
            >
              {{ buttonText }}
            </KsButton>
          </div>
        </template>
      </KsDialog>
    </Teleport>
  </li>
</template>
