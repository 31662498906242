<script setup lang="ts">
import { computed } from 'vue'
import { createReusableTemplate } from '@vueuse/core'
import { ImageCollectionMode, type ContentImageCollection } from '~/models/Content/ContentImageCollection'
import { useMedia } from '~/composables/useMedia'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import FullscreenContent from '~/components/utils/FullscreenContent.vue'
import Image from '~/components/media/Image.vue'
import Carousel from '~/components/media/Carousel.vue'
const props = defineProps<{
  content: ContentImageCollection
}>()

const { data: media, isLoading } = useMedia(computed(() => props.content.images?.destinationContentIds ?? []))
const [DefineImageText, ReuseImageText] = createReusableTemplate()
</script>

<template>
  <DefineImageText v-slot="{ image }">
    <RichTextRenderer :text="image.caption" />
    <small v-if="image.copyright">{{ $t('copyright.image', { copyright: image.copyright }) }}</small>
  </DefineImageText>

  <div
    v-if="content.mode === ImageCollectionMode.Carousel"
    class="grid"
  >
    <Carousel
      :style="{
        '--carousel-spacing': '2rem',
      }"
      nav="floating"
    >
      <template #default="{ index: activeIndex }">
        <div
          v-for="(image, index) in media"
          :key="image.contentId"
          class="grid max-h-[80vh] w-[calc(var(--col-width)*6+var(--col-gap)*6+var(--text-width))] grid-rows-[minmax(0,1fr),max-content]"
        >
          <Image
            :content="image"
            class="!h-full !w-full object-cover"
          />
          <div
            class="flex flex-col bg-white p-4 transition-opacity"
            :class="{ 'opacity-0': activeIndex !== index}"
          >
            <ReuseImageText :image />
          </div>
        </div>
      </template>
    </Carousel>
  </div>

  <div
    v-else
    class="relative z-0 grid auto-cols-fr text-base md:grid-flow-col md:pb-16"
  >
    <div
      v-for="(image, index) in media"
      :key="image.contentId"
      class="-mx-2 grid bg-white p-4"
      :class="{
        '-rotate-6': index === 0,
        'rotate-6 md:translate-y-16': index === 1,
        '-rotate-2': index === 2,
      }"
      :style="{
        zIndex: index * -1,
      }"
    >
      <FullscreenContent>
        <template #default="{ isFullscreen }">
          <Image
            :content="image"
            class="col-start-1 row-start-1 !h-full max-h-[90lvh] !w-full"
            :class="isFullscreen ? 'object-contain p-4 place-self-center' : 'object-cover'"
            :width="isFullscreen ? '100vw' : '400px'"
          />
          <div
            class="row-start-2"
            :class="isFullscreen ? 'bg-white p-4' : 'mt-2'"
          >
            <ReuseImageText :image />
          </div>
        </template>
      </FullscreenContent>
    </div>
  </div>
</template>
