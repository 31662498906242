<script setup lang="ts">
import type { GroupMember } from '~/models/Group'
import type { Task, TaskUser } from '~/models/AssignTask'
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { faPenToSquare } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsDialog, KsSpinner } from '@aschehoug/kloss'
import useGroupsStore from '~/stores/groups'
import { UserRole } from '~/models/User/UserRole'
import TeleportationTarget from '~/models/TeleportationTarget'
import { TaskStatus } from '~/models/AssignTask'
import { useAssignTask } from '~/composables/useAssignTask'
import StudentSelectionList from '~/components/products/exams/assignment/StudentSelectionList.vue'
import DeleteTaskDialog from '~/components/products/exams/assignment/DeleteTaskDialog.vue'

const { task } = defineProps<{ task: Task }>()

const { t } = useI18n()
const { isLoading } = storeToRefs(useGroupsStore())
const { loadGroupMembersById, findGroupMembersById } = useGroupsStore()
const { addTaskUsers: add, addTaskUsersAndOpen } = useAssignTask()

const open = ref(false)
const students = ref<TaskUser[]>([])

const disabled = computed(() => !students.value.length || add.isPending.value || addTaskUsersAndOpen.isPending.value)
const taskUserRequest = computed(() => ({
  users: students.value.map(({ userId, status }) => ({ userId, status })),
}))

const addUsersAndOpenTask = async () => {
  if (!task.taskId || !students.value.length) return
  await addTaskUsersAndOpen.mutateAsync({ task, users: taskUserRequest.value })
  open.value = false
}

const addTaskUsers = async () => {
  if (!task.taskId || !students.value.length) return
  await add.mutateAsync({ task, users: taskUserRequest.value })
  open.value = false
}

const filterStudents = (groupMembers: GroupMember[]): GroupMember[] =>
  groupMembers.filter(student => student.role === UserRole.Student)

watch(open, async (isOpen) => {
  if (isOpen && task.groups) {
    await loadGroupMembersById(task.groups[0])
    const allStudents = findGroupMembersById(task.groups[0])
    students.value = filterStudents(allStudents)
      .map(({ userId, firstName, lastName, fullName, role }) => ({
        userId, status: TaskStatus.Open, firstName, lastName, fullName, primaryRole: role,
      }))
  }
})
</script>

<template>
  <KsButton
    variant="secondary"
    :icon-left="faPenToSquare"
    size="small"
    @click="open = true"
  >
    Rediger elevtilgang
  </KsButton>
  <Teleport :to="TeleportationTarget.AppTop">
    <KsDialog
      :title="task.name"
      :open
      @close="open = false"
    >
      <template #body>
        <div
          class="flex flex-col gap-4"
          style="--ks-roundness: .6"
        >
          <StudentSelectionList
            v-if="!isLoading"
            v-model="students"
            @close-modal="open = false"
          />
          <KsSpinner v-else />
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end gap-4">
          <DeleteTaskDialog
            :task
            :disable-btn="disabled"
          />
          <KsButton
            variant="secondary"
            :disabled
            shape="rounded"
            @click="addTaskUsers"
          >
            <div class="flex gap-1">
              {{ add.isPending.value ? 'Lagrer prøven...' : 'Lagre prøve' }}
              <KsSpinner
                v-if="add.isPending.value"
                size="small"
                style="--ks-primary: rgb(var(--au-seagreen-30))"
              />
            </div>
          </KsButton>
          <KsButton
            variant="primary"
            :disabled
            shape="rounded"
            @click="addUsersAndOpenTask"
          >
            <div class="flex gap-1">
              {{ addTaskUsersAndOpen.isPending.value ? 'Åpner prøven...' : t('assign.openExam') }}
              <KsSpinner
                v-if="addTaskUsersAndOpen.isPending.value"
                size="small"
                style="--ks-primary: rgb(var(--au-seagreen-30))"
              />
            </div>
          </KsButton>
        </div>
      </template>
    </KsDialog>
  </Teleport>
</template>
