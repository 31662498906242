<script setup lang="ts">
import { KsCallout } from '@aschehoug/kloss'
import { useAssignTask } from '~/composables/useAssignTask'
import AssignedTaskCard from '~/components/cards/AssignedTaskCard.vue'

const { tasks, isLoading, isError } = useAssignTask()
</script>

<template>
  <section class="space-y-2">
    <h2 class="text-2xl font-bold">
      Planlagte prøver
    </h2>
    <div v-if="isLoading">
      Laster...
    </div>
    <div v-else-if="isError">
      Noe gikk galt...
    </div>
    <div v-else-if="!tasks?.length">
      <KsCallout
        variant="info"
        title="Du har ikke opprettet noen prøver"
      >
        <p class="flex flex-col space-y-2">
          <span>Når du har opprettet en prøve, kan du dele den med elever.</span>
          <span>Sørg for at læreren som skal dele prøven med elevene på prøvedagen, er medlem i gruppa.</span>
        </p>
      </KsCallout>
    </div>
    <ul
      v-else
      class="grid grid-cols-1 gap-2"
    >
      <AssignedTaskCard
        v-for="task in tasks"
        :key="`task-${task.taskId}`"
        :task
      />
    </ul>
  </section>
</template>
