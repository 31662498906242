<script setup lang="ts">
import type { FlytTestAssignment } from '~/models/Assignment'
import { computed, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { Chart, ArcElement, DoughnutController } from 'chart.js'
Chart.register(ArcElement, DoughnutController)

const { t } = useI18n()

const props = defineProps<{ reportData: FlytTestAssignment[] }>()
const resultsModel = defineModel<{correct: number; wrong: number; unanswered: number}>()

const groupData = computed(() => {
  const dataFields = props.reportData.flatMap(assignment => {
    return assignment.responseSummary.flatMap(summary => {
      return summary.latestAnswer?.data
    })
  })

  const missingCount = dataFields.filter(answer => answer === undefined).length
  //console.log('missingCount: ', missingCount) // @todo: How do we handle this? Is this a problem at all?

  const items = dataFields
    .filter(answer => answer !== undefined)
    .map(answer => {
      return JSON.parse(answer)
    })
    .flatMap(data => {
    return data.items
  })

  let correctAnswers = 0
  let wrongAnswers = 0
  let unansweredAnswers = 0

  items.forEach(item => {
    if (item.score === null) {
      unansweredAnswers++ //@todo: This can suddenly be more than 1...
      return
    }
    correctAnswers += item.score.correctAnswers
    wrongAnswers += item.score.totalQuestions - item.score.correctAnswers
  })

  return {
    correct: correctAnswers,
    wrong: wrongAnswers,
    unanswered: unansweredAnswers,
  }
})

watch (groupData, () => {
  resultsModel.value = groupData.value
  console.log()
}, { immediate: true })

onMounted(() => {
  initGraph()
})

const initGraph = () => {
  const chartElement = document.getElementById('groupGraph') as HTMLCanvasElement | null
  if (!chartElement) return

  new Chart(
    chartElement,
    {
      type: 'doughnut',
      options: {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false
          },
        }
      },
      data: {
        labels: [
          t('report.status.correct'),
          t('report.status.wrong'),
          t('report.status.unanswered'),
        ],
        datasets: [{
          data: Object.values(groupData.value),
          backgroundColor: [
            '#006A52',
            '#FF6472',
            '#D4D4D4'
          ],
        }]
      }
    }
  )
}
</script>

<template>
  <div>
    <div class="h-[380px] content-center">
      <canvas id="groupGraph" />
    </div>
  </div>
</template>
