<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import { TaskStatus } from '~/models/AssignTask'
import { useAssignTask } from '~/composables/useAssignTask'
import AssignedTaskCard from '~/components/cards/AssignedTaskCard.vue'

const { tasks, isLoading, isError } = useAssignTask()
const { userId } = storeToRefs(useAuthStore())

const studentTasks = computed(() =>
  tasks.value?.filter(task =>
    task.status === TaskStatus.Open &&
    task.users?.some(user => user.userId === userId.value && user.status === TaskStatus.Open)
  ) ?? [])
</script>

<template>
  <div v-if="isLoading">
    Laster...
  </div>
  <div v-else-if="isError">
    Noe gikk galt...
  </div>
  <div v-else-if="!studentTasks?.length">
    Du har ingen tildelte terminprøver...
  </div>
  <ul
    v-else
    class="grid grid-cols-1 gap-2"
  >
    <AssignedTaskCard
      v-for="task in tasks"
      :key="`task-${task.taskId}`"
      :task
    />
  </ul>
</template>
