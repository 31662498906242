<script setup lang="ts">
import type { Task } from '~/models/AssignTask'
import { computed, ref } from 'vue'
import { KsButton, KsIcon, KsInput } from '@aschehoug/kloss'
import { debounce } from '~/utils/functionUtils'
import ScrollBox from '~/components/utils/ScrollBox.vue'
import StudentEditItem from '~/components/products/exams/assignment/StudentEditItem.vue'

const { task } = defineProps<{ task: Task }>()

const searchQuery = ref('')
const isScrolled = ref(false)
const isLoadingList = ref(false)

const filteredStudents = computed(() => task.users ? task.users
  .filter(student => (student.fullName ?? '').toLowerCase().includes(searchQuery.value.toLowerCase()))
  .sort((a, b) => (a.fullName ?? '').localeCompare(b.fullName ?? '')) : [])

const handleScroll = (e: Event) => {
  const target = e.target as HTMLElement
  isScrolled.value = target.scrollTop > 0
}

const onInput = debounce((event: Event) => {
  searchQuery.value = (event.target as HTMLInputElement).value
  isLoadingList.value = false
}, 500)

const handleInput = (event: Event) => {
  isLoadingList.value = true
  onInput(event)
}
</script>

<template>
  <div class="rounded-md bg-gray-5 pt-4">
    <div
      class="flex w-full items-center justify-between px-4 pb-4 transition-all"
      :class="{ 'shadow-md': isScrolled }"
    >
      <div class="relative w-2/3">
        <div class="absolute left-5 top-1/2 z-10 flex -translate-y-1/2 justify-center text-base text-gray-40">
          <KsIcon
            icon="magnifying-glass"
            :fade="isLoadingList"
            animation-duration="500ms"
            loop-animation
          />
        </div>
        <KsInput
          :value="searchQuery"
          type="text"
          name="search"
          style="--ks-input: white; --ks-inputhover: white"
          placeholder="Søk blant elevene..."
          class="!rounded !pl-12 !text-base placeholder:text-gray-40"
          @input="handleInput"
        />
        <KsButton
          v-if="searchQuery.length"
          icon-left="xmark"
          shape="square"
          variant="tertiary"
          size="small"
          class="absolute right-3 top-1/2 z-10 -translate-y-1/2 text-xl"
          @click="searchQuery = ''"
        />
      </div>
    </div>
    <ScrollBox
      class="max-h-96 !overflow-x-hidden px-4 pb-4"
      @scroll="handleScroll"
    >
      <transition-group
        v-if="filteredStudents?.length"
        name="list"
        tag="ul"
        class="relative flex flex-col"
      >
        <StudentEditItem
          v-for="student in filteredStudents"
          :key="`student-${student.userId}`"
          :student
          :task
        />
      </transition-group>
      <div
        v-else
        class="text-sm"
      >
        Fant ingen elever. <br> Sørg for at du har skrevet riktig navn.
      </div>
    </ScrollBox>
  </div>
</template>

<style scoped>
.list-move,
.list-enter-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-leave-active {
  transition: all 0.2s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

.list-leave-active {
  position: absolute;
}
</style>
