import type { Task, TaskUser, TaskUserRequest } from '~/models/AssignTask'
import { computed, inject } from 'vue'
import { storeToRefs } from 'pinia'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import useGroupsStore from '~/stores/groups'
import { TaskStatus } from '~/models/AssignTask'
import { useTasksApi } from '~/api/tasksApi'
import { useSseApi } from '~/api/sseApi'

export function useAssignTask() {
  const {
    getTasks: apiGetTasks,
    createTask: apiCreateTask,
    updateTask: apiUpdateTask,
    deleteTask: apiDeleteTask,
    closeTask: apiCloseTask,
    openTask: apiOpenTask,
    getTaskUsers: apiGetTaskUsers,
    addTaskUsers: apiAddTaskUsers,
    updateTaskUser: apiUpdateTaskUser,
  } = useTasksApi()

  const { createStream, closeStream, notifyStream, subscribeStream } = useSseApi()

  const { groups, isLoading: isLoadingGroups } = storeToRefs(useGroupsStore())

  const toast = <Toast>inject('ksToast')
  const groupIds = computed(() => groups.value.map(group => group.groupId))
  const queryClient = useQueryClient()
  const queryKey = ['assigned_tasks', groupIds.value]

  const {
    data: tasks,
    isLoading,
    isError,
  } = useQuery({
    queryKey,
    queryFn: () => apiGetTasks({ groups: groupIds.value }),
    enabled: computed(() => !isLoadingGroups.value),
    staleTime: Infinity,
  })

  const createTask = useMutation({
    mutationFn: async (task: Partial<Task>) => apiCreateTask(task),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onSettled: (data, error) => error
      ? toast.error('Noe gikk galt med å opprette prøven')
      : toast.success('Prøven ble opprettet'),
  })

  const updateTask = useMutation({
    mutationFn: async (task: Task) => apiUpdateTask(task),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const deleteTask = useMutation({
    mutationFn: async (task: Task) => apiDeleteTask(task),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey })
      await closeStream(String(variables.taskId))
    },
    onSettled: (data, error) => error
      ? toast.error('Noe gikk galt med å slette prøven')
      : toast.success('Prøven ble slettet'),
  })

  const closeTask = useMutation({
    mutationFn: async (task: Task) => apiCloseTask(task),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey })
      await notifyStream(String(variables.taskId), { id: crypto.randomUUID(), status: TaskStatus.Closed, all: true })
    },
    onSettled: (data, error) => error
      ? toast.error('Noe gikk galt med å låse prøven')
      : toast.success('Prøven ble låst'),
  })

  const openTask = useMutation({
    mutationFn: async (task: Task) => apiOpenTask(task),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey })
      await createStream(String(variables.taskId))
    },
    onSettled: (data, error) => error
      ? toast.error('Noe gikk galt med å åpne prøven')
      : toast.success('Prøven ble åpnet'),
  })

  const getTaskUsers = useMutation({
    mutationFn: async (task: Task) => apiGetTaskUsers(task),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  })

  const addTaskUsers = useMutation({
    mutationFn: async ({ task, users }: {
      task: Task
      users: TaskUserRequest
    }) => apiAddTaskUsers(task, users),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
    onSettled: (data, error) => error
      ? toast.error('Noe gikk galt med å lagre prøven')
      : toast.success('Prøven ble lagret'),
  })

  const updateTaskUser = useMutation({
    mutationFn: async ({ task, user, status }: {
      task: Task
      user: TaskUser
      status: TaskStatus
    }) => apiUpdateTaskUser(task, user, status),

    onMutate: async ({ task, user, status }) => {
      await queryClient.cancelQueries({ queryKey })

      const previousTasks = queryClient.getQueryData(queryKey)

      queryClient.setQueryData(queryKey, (oldData: Task[]) => {
        return oldData.map((t: Task) => {
          if (t.taskId === task.taskId) {
            return {
              ...t,
              users: t.users?.map((u) =>
                u.userId === user.userId ? { ...u, status } : u
              ),
            }
          }
          return t
        })
      })

      return { previousTasks }
    },

    onError: (err, variables, context) => {
      queryClient.setQueryData(queryKey, context?.previousTasks)
    },

    onSuccess: async (data, variables) => {
      await notifyStream(String(variables.task.taskId), {
        id: crypto.randomUUID(),
        status: variables.user.status === TaskStatus.Open ? 'CLOSED' : 'OPEN',
        all: false,
        userId: String(variables.user.userId),
      })
    },

    onSettled: async (data, error, variables) => {
      await queryClient.invalidateQueries({ queryKey })
      return error
        ? toast.error('Noe gikk galt med å oppdatere statusen til eleven')
        : toast.success(`Prøven ble ${variables.user.status === TaskStatus.Open ? 'stengt' : 'åpnet'} for ${variables.user.fullName}`)
    },
  })

  const addTaskUsersAndOpen = useMutation({
    mutationFn: async ({ task, users }: {
      task: Task
      users: TaskUserRequest
    }) => {
      await apiAddTaskUsers(task, users)
      await apiOpenTask(task)
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey })
      await createStream(String(variables.task.taskId))
    },
    onSettled: (data, error) => error
      ? toast.error('Noe gikk galt med å åpne terminprøven og dele med elevene')
      : toast.success('Terminprøven ble åpnet og delt med elevene'),
  })

  return {
    tasks,
    isLoading,
    isError,
    createTask,
    updateTask,
    deleteTask,
    closeTask,
    openTask,
    getTaskUsers,
    addTaskUsers,
    updateTaskUser,
    addTaskUsersAndOpen,
  }
}
