<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useAuthStore from '~/stores/auth'
import TeacherAssignedTasks from '~/components/products/exams/assignment/TeacherAssignedTasks.vue'
import StudentAssignedTasks from '~/components/products/exams/assignment/StudentAssignedTasks.vue'

const { isTeacher, isStudent } = storeToRefs(useAuthStore())
</script>

<template>
  <TeacherAssignedTasks v-if="isTeacher" />
  <StudentAssignedTasks v-else-if="isStudent" />
</template>
