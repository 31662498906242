<script setup lang="ts">
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useDescriptor from '~/composables/useDescriptor'
import { useContentHelper } from '~/composables/useContentHelper'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'
import LearningPathLink from '~/components/utils/LearningPathLink.vue'
import CardMenu from '~/components/cards/buttons/CardMenu.vue'

const props = withDefaults(defineProps<{
  resource: BaseItem
  isAnchor?: boolean
  noImage?: boolean
  showLabel?: boolean
}>(), {
  showLabel: true
})

const { getTitle, isLearningPath } = useContentHelper()
const { buildResourceUrl } = useUrlBuilder()
const { getDescriptor } = useDescriptor()
const { t } = useI18n()

const title = computed(() => getTitle(props.resource))
const link = computed(() => buildResourceUrl(props.resource))
const label = getDescriptor(props.resource)

const attributes = computed(() => isLearningPath(props.resource)
  ? {
    resource: props.resource
  }
  : {
    link: link.value,
    isAnchor: props.isAnchor
  }
)
</script>

<template>
  <div class="relative flex size-full animate-fade flex-col gap-2 bg-[--card-bg] px-6 py-4 text-xl font-bold text-[--card-text] outline outline-1 outline-[--card-border] ring-inset transition duration-100 has-[a:hover]:bg-[--card-hover] has-[a:focus-visible]:ring has-[a:hover]:ring has-[a:hover]:ring-[--card-border]">
    <aside
      v-if="resource.new"
      class="pointer-events-none absolute right-0 top-0 z-20 rounded-bl-2xl bg-green-40 p-1.5 pl-2 text-sm font-medium uppercase tracking-wider text-white"
      v-text="t('newShort')"
    />
    <div
      :id="`card-${resource.locationId}`"
      class="contents"
    >
      <div
        v-if="showLabel"
        class="text-xs font-semibold uppercase text-[var(--card-accent)]"
      >
        {{ label }}
      </div>

      <div class="break-words text-lg font-medium">
        {{ title }}
      </div>
    </div>

    <div
      v-if="resource.thumbnail && !noImage"
      class="aspect-video"
    >
      <img
        :src="resource.thumbnail"
        class="size-full overflow-hidden object-cover"
        alt=""
      >
    </div>

    <component
      :is="isLearningPath(resource) ? LearningPathLink : LinkOrAnchor"
      :aria-labeledby="`card-${resource.locationId}`"
      class="absolute inset-0 select-none opacity-0"
      v-bind="attributes"
    />

    <CardMenu
      class="relative mt-auto self-start"
      :resource="resource"
      button-variant="border"
      style="--ks-border: transparent"
    />
  </div>
</template>
