<script lang="ts" setup>
import type { FavoriteListCreateInput } from '~/models/Favorites'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { KsButton, KsIcon } from '@aschehoug/kloss'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { getColorForId, forceColorShade, colors } from '~/utils/colors'
import useToast from '~/composables/useToast'
import { useFavorites } from '~/composables/useFavorites'
import FavoriteDialog from '~/components/favorites/FavoriteDialog.vue'
import StackedCard from '~/components/cards/StackedCard.vue'
import CardMenu from '~/components/cards/buttons/CardMenu.vue'

const { t } = useI18n()
const { ksToast } = useToast()

const { updateFavoriteList, deleteFavoriteList, favoriteLists } = useFavorites()

const { isLoading, data: lists } = favoriteLists
</script>

<template>
  <ul
    v-show="isLoading"
    class="grid gap-x-8 gap-y-16 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
  >
    <KsSkeletonWrapper
      v-for="n in 12"
      :key="n"
      class="min-h-64 overflow-hidden rounded-2xl"
    >
      <KsSkeleton
        class="motion-safe:animate-fade-in-up"
        width="100%"
        height="100%"
      />
    </KsSkeletonWrapper>
  </ul>
  <ul
    v-show="!isLoading"
    class="grid gap-8 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3"
  >
    <StackedCard
      v-for="(list, i) in lists"
      :key="i"
      class="min-h-64 self-stretch rounded-lg"
      :item="list"
      :colors="[forceColorShade(getColorForId(list.id ?? 0, colors), '10')]"
      :darken="true"
      :depth="list.favorites?.length ?? 0"
      :stack-offset="3"
      :padded-stack="false"
      :style="{
        zIndex: (lists?.length || 1) - i,
      }"
    >
      <template #default="{ item }">
        <router-link
          class="cursor-pointer rounded-md p-6 pb-16 text-black transition-all duration-150 hover:bg-white focus-visible:ring"
          :to="{ name: 'favorite_list', params: { listId: list.id } }"
        >
          <h2
            class="pb-2 text-2xl text-black"
            v-text="item?.name || ''"
          />
          <p
            class="pb-2 text-lg"
            v-text="(item?.favorites?.length ?? 0) > 0 ? t('favorite.counted', { number: item?.favorites.length }) : t('favorite.noneCounted')"
          />
          <CardMenu
            v-if="item"
            class="absolute bottom-4 left-4 isolate z-20"
            :resource="item"
            direction="down right"
          >
            <template #toggle>
              <KsButton
                icon-left="ellipsis"
                shape="round"
              />
            </template>
            <template #menu="{ item }">
              <FavoriteDialog
                v-if="!item?.isDefault"
                action="delete"
                :item="item"
                @delete="(item) => deleteFavoriteList.mutateAsync(item)
                  .then(() => ksToast.success(t('favorite.deleteSuccess')))
                  .catch(() => ksToast.error(t('error')))"
              />
              <FavoriteDialog
                action="edit"
                :item="item"
                @update="(item) => updateFavoriteList.mutateAsync(item)
                  .then(() => ksToast.success(t('favorite.updateSuccess')))
                  .catch(() => ksToast.error(t('error')))"
              />
            </template>
          </CardMenu>
        </router-link>
      </template>
    </StackedCard>
    <li class="self-stretch rounded-md bg-white">
      <FavoriteDialog
        action="create"
        :item="null"
      >
        <template #activator>
          <KsButton
            class="relative !grid !h-full !rounded-md"
            stretch
          >
            <KsIcon
              class="place-self-center text-8xl"
              icon="plus"
            />
            <span
              class="text-2xl"
              v-text="t('favorite.createListButtonName')"
            />
          </KsButton>
        </template>
      </FavoriteDialog>
    </li>
  </ul>
</template>

