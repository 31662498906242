<script setup lang="ts">
import type { TeacherArticle } from '~/models/TeacherArticle'
import type { ContentPackage } from '~/models/Content/ContentPackage'
import type { ImageRelationField } from '~/models/Content/BaseField'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useQuery } from '@tanstack/vue-query'
import { KsIcon } from '@aschehoug/kloss'
import useProductStore from '~/stores/product'
import useAuthStore from '~/stores/auth'
import { ContentType } from '~/models/Content/ContentType'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useProductHelper from '~/composables/useProductHelper'
import useImage from '~/composables/useImage'
import useDescriptor from '~/composables/useDescriptor'
import useContentApi from '~/api/contentApi'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'

const { content, showBreadcrumbs = true } = defineProps<{
  content: ContentPackage
  showBreadcrumbs?: boolean
}>()

const router = useRouter()
const { t } = useI18n()
const { getDescriptor } = useDescriptor()
const { getImageSrc, getImageAltText } = useImage()
const { getProductFromResource } = useProductStore()
const { addonProductColorMap } = useProductHelper()
const { resolveBaseUrl, buildParentPackageRoute, buildTeacherArticleUrl } = useUrlBuilder()
const { findContents } = useContentApi()
const { isTeacher } = storeToRefs(useAuthStore())

const label = computed(() => getDescriptor(content))
const product = computed(() => getProductFromResource(content))
const showParentLink = computed(() => content.parentLocationContentTypeIdentifier &&
  [ContentType.ProductHeader, ContentType.ProductPackage].includes(content.parentLocationContentTypeIdentifier))
const color = computed(() => product.value && addonProductColorMap[product.value.ean] || 'green')

const breadcrumbs = computed(() => {
  const list = []

  if (product.value) {
    list.push({ title: product.value.aunivers.name, to: { path: resolveBaseUrl(product.value) } })
  }
  if (showParentLink.value) {
    list.push({
      title: content.parentLocationName,
      to: { path: buildParentPackageRoute(content.parentLocationId, content.parentLocationName, product.value) }
    })
  }
  list.push({ title: content.title, to: { path: '' } })

  return list
})

const image = computed(() => {
  const imageField = content.image as ImageRelationField

  return Number(content.image?.destinationContentId) > 0
    ? { src: getImageSrc(imageField, 'banner_large'), altText: getImageAltText(imageField) }
    : { src: content.thumbnail, altText: content.thumbnailAlt }
})

const { data: teacherArticle } = useQuery({
  enabled: computed(() => isTeacher.value && Number(content.teacherGuide?.destinationContentId) > 0),
  staleTime: Infinity,
  queryKey: ['teacher-article', content.locationId],
  queryFn: () => findContents<TeacherArticle>({
    contentIdCriterion: [Number(content.teacherGuide!.destinationContentId)],
    contentTypeCriterion: [ContentType.TeacherArticle],
    mainLocationCriterion: true,
  }, 1).then(([data]) => data),
})
</script>

<template>
  <section
    class="mx-auto mb-8 h-full py-8"
    :class="`bg-${color}-5`"
  >
    <div
      v-if="showBreadcrumbs"
      class="mx-auto max-w-screen-au px-4 pb-8 sm:px-8"
    >
      <nav :aria-label="t('breadcrumbs')">
        <ol class="flex items-center gap-2 text-lg">
          <li
            v-for="({ title, to }, index) in breadcrumbs"
            :key="index"
          >
            <RouterLink
              :to
              :aria-current="index === breadcrumbs.length - 1 && 'page'"
              class="hover:text-seagreen-40 focus-visible:ring"
              :class="index === breadcrumbs.length - 1 ? 'font-medium pointer-events-none' : 'underline underline-offset-2'"
            >
              {{ title }}
            </RouterLink>
            <KsIcon
              v-if="index < breadcrumbs.length - 1"
              class="ml-2"
              icon="chevron-right"
              :scale=".8"
            />
          </li>
        </ol>
      </nav>
    </div>
    <div class="mx-auto grid max-w-screen-au grid-cols-1 items-center gap-8 px-4 sm:px-8 md:grid-cols-2">
      <div
        v-if="image?.src"
        class="aspect-[3/2] rounded"
      >
        <img
          :src="image.src"
          class="size-full rounded object-cover"
          :alt="image.altText"
        >
      </div>
      <article class="au-cms prose-sm animate-fade">
        <span
          class="order-first self-start rounded-md px-2 py-1 text-xs font-semibold text-orange-5"
          :class="`bg-${color}-50`"
          v-text="label"
        />
        <h1
          class="my-4 text-purple-60"
          v-text="content?.title"
        />
        <RichTextRenderer :text="content?.intro" />
        <button
          v-if="isTeacher && teacherArticle"
          class="rounded-3xl border border-coral-60 bg-orange-20 px-6 py-2 font-medium text-coral-60 transition hover:bg-orange-10 focus-visible:ring"
          @click.prevent="router.push(buildTeacherArticleUrl(teacherArticle))"
        >
          {{ t('filters.forTeacher') }}
        </button>
      </article>
    </div>
  </section>
</template>
