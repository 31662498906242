import type { SessionUser } from '~/models/User/User'
import type { Organization } from '~/models/Organization'
import { RudderAnalytics } from '@rudderstack/analytics-js'
import { UserRole } from '~/models/User/UserRole'

export default () => {
  const options = {
    enabled: import.meta.env.VITE_RUDDERSTACK_ENABLED === 'true',
    writeKey: import.meta.env.VITE_RUDDERSTACK_WRITE_KEY,
    dataPlaneUrl: import.meta.env.VITE_RUDDERSTACK_DATAPLANE_URL,
  }

  const rudderAnalytics = new RudderAnalytics()

  rudderAnalytics.load(options.writeKey, options.dataPlaneUrl, {})

  const initRudderstack = (user: SessionUser, organization?: Organization) => {
    if (!options.enabled || !user.hash) return
    rudderAnalytics.identify(user.hash, {
      role: user?.role[0] ?? UserRole.Anonymous,
      grades: user.grades.map((g) => g.code),
      subjects: user.subjects.map((s) => s.code),
    })
    if (organization?.number) {
      rudderAnalytics.group(organization.number, {
        name: organization?.name ?? 'Unknown organization',
      })
    }
  }

  const trackRoute = () => {
    if (!options.enabled) return
    rudderAnalytics.page('Page', 'Page View')
  }

  return { initRudderstack, trackRoute }
}
