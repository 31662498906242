import type { Assignment, AssignmentResponse, AssignmentReportLine } from '~/models/Assignment'
import { useAuthStore } from '~/stores/auth'
import { externalClient } from '~/api/client/externalClient'

export default function useAssignmentApi() {

  const getAssignmentResponse = async (assignmentKey: string): Promise<AssignmentResponse[]> => {
    const { activeUserGroup }  = useAuthStore()
    const { data } = await externalClient.get(
      `/frontend/assignment/${assignmentKey}/response?groupIds=${activeUserGroup?.groupId}`,
    )

    return data
  }

  const getAssignment = async (assignmentKey: string): Promise<Assignment> => {
    const { data } = await externalClient.get(`/frontend/assignment/${assignmentKey}`)

    return data
  }

  const getReport = async (
    groupId: string,
    lastActivityAfter: string,
    lastActivityBefore: string,
    contentType: string,
    context: string,
    subject: string,
    grade: string
  ): Promise<AssignmentReportLine[]> => {
    const { data } = await externalClient.get(
      '/frontend/assignment/report',
      { params: {
          groupId: groupId,
          lastActivityAfter: lastActivityAfter,
          lastActivityBefore: lastActivityBefore,
          contentType: contentType,
          context: context,
          subject: subject,
          grade: grade,
        } }
    )

    return data
  }

  return {
    getAssignmentResponse,
    getAssignment,
    getReport,
  }
}
