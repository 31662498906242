<script setup lang="ts">
import type { FlytTestAssignment } from '~/models/Assignment'
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import GroupGraph from '~/components/reports/GroupGraph.vue'
const { t } = useI18n()
import { faMinus, faX, faCheck } from '@fortawesome/pro-regular-svg-icons'
import { KsIcon } from '@aschehoug/kloss'
import { waitFor } from '~/utils/asyncUtils'
import useGroupsStore from '~/stores/groups'
import { storeToRefs } from 'pinia'

const groupsStore = useGroupsStore()
const { findGroupById } = groupsStore
const { hasGroups, isLoading: isLoadingGroups } = storeToRefs(groupsStore)

const props = defineProps<{
  reportData: FlytTestAssignment[]
  groupId: string | undefined
}>()

const resultsModel = ref({
  correct: 0,
  wrong: 0,
  unanswered: 0,
})

const percentageObject = computed(() => {
  const total = Object.values(resultsModel.value).reduce((sum, value) => sum + value, 0)
  if (total === 0) {
    return Object.fromEntries(Object.keys(resultsModel.value).map(key => [key, 0]))
  }
  return Object.fromEntries(Object.entries(resultsModel.value).map(([key, value]) => [key, Math.round((value / total) * 100)]))
})

const activeGroupName = computed((): string => {
  return findGroupById(props.groupId)?.name || ''
})

const answerPercentage = computed(() => percentageObject.value.correct + percentageObject.value.wrong)
</script>

<template>
  <header class="relative grid flex-col justify-center gap-8 overflow-hidden rounded-lg border-[3px] border-green-20 bg-white text-blue-60 xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-2">
    <div class="relative mx-auto size-full max-w-screen-au content-center px-8 transition-all grid grid-rows-3">
      <h3
        class="py-8 font-semibold uppercase text-xl"
        v-text="t('report.banner.title')"
      />
      <div>
        <p
          class="py-1 font-semibold text-lg"
          v-text="t('report.banner.description')"
        />
        <h2
          class="relative z-10 py-1 font-semibold text-3xl py-2"
          v-text="t('metadata.subjects.ENG')"
        />
        <p
        >
          {{ t('report.banner.group') }}
          <span class="font-semibold" v-text="activeGroupName"></span>
        </p>
      </div>
      <div class="flex content-center flex-wrap">
        <p v-text="t('report.banner.responseRate')" />
        <p
          class="font-semibold px-1"
          v-text="answerPercentage + '%'"
        />
      </div>
    </div>
    <div class="grid px-4 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2">
      <div class="m-auto content-center">
        <p
          class="text-lg font-semibold"
          v-text="t('report.banner.total')"
        />
        <ul>
          <li class="p-1">
            <span class="inline-block content-center bg-[#006A52] px-1 pt-1 text-white">
              <KsIcon :icon="faCheck" />
            </span>
            <p
              class="inline-block pl-2"
              v-text="`${percentageObject.correct}% ${t('report.status.correct')}`"
            />
          </li>
          <li class="p-1">
            <span class="inline-block content-center bg-[#FF6472] px-1 pt-1 text-white">
              <KsIcon :icon="faX" />
            </span>
            <p
              class="inline-block pl-2"
              v-text="`${percentageObject.wrong}% ${t('report.status.wrong')}`"
            />
          </li>
          <li class="p-1">
            <span class="inline-block content-center bg-[#D4D4D4] px-1 pt-1 text-white">
              <KsIcon :icon="faMinus" />
            </span>
            <p
              class="inline-block pl-2"
              v-text="`${percentageObject.unanswered}% ${t('report.status.unanswered')}`"
            />
          </li>
        </ul>
      </div>
      <div class="content-center">
        <GroupGraph
          v-model="resultsModel"
          :report-data="reportData"
        />
      </div>
    </div>
  </header>
</template>
